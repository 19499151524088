import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Projects from "../components/projects"
import Welcome from "../components/welcome"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Welcome/>
    <Projects/>
  </Layout>
)

export default IndexPage
