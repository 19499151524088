import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export default function Welcome () {
    const data = useStaticQuery (
        graphql`
            query {
                site {
                    siteMetadata {
                        welcome {
                            title
                            subtitle
                        }
                    }
                }
            }
        `
    )
    return (
        <div className="row-auto col-span-5 col-start-1 lg:col-span-3 lg:col-start-2 lg:-mx-4" style={{"minHeight": "80vh", "paddingTop": "20vh"}}>
            <h1 className="mt-2 mb-4">{data.site.siteMetadata.welcome.title}</h1>
            <h5 className="mb-12">{data.site.siteMetadata.welcome.subtitle}</h5>
        </div>
    )
}