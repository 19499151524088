import React, { Fragment } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Projects() {
    const data = useStaticQuery(
        graphql`
        query ProjectListQuery {
            allMarkdownRemark 
                (sort: {order: ASC, fields: [frontmatter___order]}) 
            {
                edges {
                    node {
                        id
                        frontmatter {
                            path 
                            title
                            name
                            colors
                            order
                            previewImage {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 1300
                                        placeholder: BLURRED
                                        formats: [AUTO, WEBP, AVIF]
                                      )
                                }
                            }
                        }
                    }
                }
            }
        }
    `
    )

    const elements = data.allMarkdownRemark.edges.map((post, index) => (
        <div key={post.node.id} className={"relative animate-scale -mx-6 mb-6 col-span-5 col-start-1 sm:mb-12 lg:mb-20 sm:col-span-4 bg-" + post.node.frontmatter.colors[0] + "-lightest " + (index % 2 ? "sm:col-start-2" : "")}  >
            <div style={{ 'paddingTop': '50%' }}>
                <Link to={post.node.frontmatter.path}>
                    <div className="absolute top-0 left-0 w-full h-full z-10 flex flex-wrap content-center">
                            <div className="grid grid-cols-4 pb-8 px-6 md:pr-8">
                                <h6 className={"col-span-4 lg:col-span-3 col-start-1 lg:col-start-2 align-bottom md:mb-2 lg:leading-6 text-" + post.node.frontmatter.colors[1] + "-dark"}>{post.node.frontmatter.name}</h6>
                                <h2 className={"col-span-4 lg:col-span-3 col-start-1 lg:col-start-2 text-" + post.node.frontmatter.colors[1] + "-dark"}>{post.node.frontmatter.title}</h2>
                            </div>
                    </div>
                    <div className="absolute top-0 left-0 opacity-0 md:hover:opacity-100 w-full h-full z-20 transition duration-500 ease-in-out">
                        <div className="w-full relative">
                            <div className={"absolute z-30 left-12 transform origin-top-left rotate-90 h-12 py-3 bg-" + post.node.frontmatter.colors[1] + "-dark"} style={{ 'width': '50%' }}>
                                <h6 className={"text-center font-bold text-" + post.node.frontmatter.colors[0] + "-light"}>Read more</h6>
                            </div>
                        </div>
                        <GatsbyImage image={getImage(post.node.frontmatter.previewImage)} alt={post.node.frontmatter.name} className="ml-12 h-full" />
                    </div>
                </Link>
            </div>
        </div>
    ))

    return (
        <Fragment>
            <h4 className="col-span-4 col-start-1 lg:col-start-2 lg:-mx-4 mb-4">Select Work.</h4>
            {elements.slice(0,2)}
            <Link to="/about" className="col-span-5 lg:col-span-3 col-start-1 lg:col-start-2 lg:-mx-4 mb-2 mt-4 lg:mt-8"><h4 className="underline">View a complete overview of my work</h4></Link>
            <h5 className="col-span-5 lg:col-span-3 col-start-1 lg:col-start-2 lg:-mx-4 mb-8 md:mb-12 lg:mb-24">I have worked across different industries and types of organizations with firms such as Mercedes-Benz, Deutsche Bank, Glovo, and codesandbox. Please note that most of my work is under NDA.</h5>
            {elements[2]}
        </Fragment>
    )
}



